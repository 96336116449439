import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import CardHeading from '../components/common/CardHeading';
import ContentContainer from '../components/common/ContentContainer';
import ContentfulContent from '../components/common/ContentfulContent';
import LoadingButton from '../components/common/LoadingButton';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { extractContent } from './../util';
import { FaqSection } from './events/faq';
import { eventContactForm } from '../components/members/api';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(1000)
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  school: Yup.string()
    .max(1000)
    .required('Organization name is required'),
  position: Yup.string()
    .max(1000)
    .required('Position is required'),
  address1: Yup.string()
    .max(1000)
    .required('Address is required'),
  address2: Yup.string().max(1000),
  eventType: Yup.string()
    .max(1000)
    .required('Event type is required'),
  eventText: Yup.string()
    .max(5000)
    .required('Message is required'),
  receiveEmails: Yup.boolean().required('A selection is required')
});

interface EventInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const EventInquiryForm = (props: EventInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        email: '',
        position: '',

        school: '',
        address1: '',
        address2: '',

        receiveEmails: false,

        eventType: '',
        eventText: ''
      }}
      validationSchema={ContactFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Name</FormLabel>
              <FormField type="text" name="name" placeholder="John Doe" />
              <FormError error={errors.name} touched={touched.name} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
              />
              <FormError error={errors.email} touched={touched.email} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Phone</FormLabel>
              <FormField type="text" name="phone" placeholder="555-555-5555" />
              <FormError error={errors.phone} touched={touched.phone} />
            </FormGroup>
          </div>
          <div className="w-full md:w-2/3 mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Organization Name</FormLabel>
              <FormField
                type="text"
                name="school"
                placeholder="Organization/District"
              />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <FormField type="text" name="position" placeholder="Teacher" />
              <FormError error={errors.position} touched={touched.position} />
            </FormGroup>
          </div>

          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Address</FormLabel>
              <FormField
                type="text"
                name="address1"
                placeholder="123 Any Street"
              />
              <FormError error={errors.address1} touched={touched.address1} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Address (line 2)</FormLabel>
              <FormField
                type="text"
                name="address2"
                placeholder="Anytown, KS"
              />
              <FormError error={errors.address2} touched={touched.address2} />
            </FormGroup>
          </div>

          <div className="flex flex-col">
            <div className="w-1/2">
              <FormGroup>
                <div className="mb-2">
                  <FormLabel>I need help with an upcoming event:</FormLabel>
                </div>
                <Select
                  name="eventType"
                  onChange={val => setFieldValue('eventType', val.label)}
                  options={[
                    { value: 'Institutes', label: 'Institutes' },
                    { value: 'Calendar Days', label: 'Calendar Days' },
                    { value: 'Supper Club', label: 'Supper Club' },
                    { value: 'Saturday Reunion', label: 'Saturday Reunion' },
                    {
                      value: 'Asynchronous Courses',
                      label: 'Asynchronous Courses'
                    },
                    { value: 'Other', label: 'Other' }
                  ]}
                />
                <FormError
                  error={errors.eventType}
                  touched={touched.eventType}
                />
              </FormGroup>
            </div>
            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>How Can We Help?</FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="eventText"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.eventText} touched={touched.eventText} />
            </FormGroup>
          </div>
          <div className="flex flex-col mb-3">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700">
                    Yes, I want to receive emails from the Reading and Writing
                    Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>
          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const PDFormSchema = Yup.object().shape({
  'First Name': Yup.string()
    .max(40)
    .required('First name is required'),
  'Last Name': Yup.string()
    .max(80)
    .required('Last name is required'),
  LEADCF1: Yup.string()
    .max(255)
    .required('Position is required'),
  Email: Yup.string()
    .max(100)
    .required('Email is required'),
  Phone: Yup.string().max(30),
  LEADCF102: Yup.boolean(),
  Company: Yup.string()
    .max(250)
    .required('Organization name is required'),
  LEADCF11: Yup.string()
    .max(250)
    .required('School district is required'),
  Street: Yup.string()
    .max(30)
    .required('Address is required'),
  City: Yup.string()
    .required('City is required')
    .max(30),
  State: Yup.string()
    .required('State is required')
    .max(30),
  'Zip Code': Yup.string().max(30),
  LEADCF106: Yup.boolean(),
  LEADCF109: Yup.boolean(),
  LEADCF104: Yup.boolean(),
  LEADCF103: Yup.boolean(),
  LEADCF10: Yup.string()
    .max(2000)
    .required('Message is required')
});

interface PDInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const PDInquiryForm = (props: PDInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        // hidden values for zoho
        xnQsjsdp:
          'ccb704d2a4b61bdaa712a110fa4bb47018ce5804dac2ac848c44556a458bdfd7',
        zc_gad: '',
        xmIwtLD:
          'ea590dd769bf57c32af50e0b57f97988d4b9eb171a0839195a67ab933a3decf2',
        actionType: 'TGVhZHM=',

        // Form fields
        'First Name': '',
        'Last Name': '',
        LEADCF1: '', // position
        Email: '',
        Phone: '',
        LEADCF102: '', // NYC orgd
        Company: '',
        LEADCF11: '', // School district
        Street: '',
        City: '',
        State: '',
        'Zip Code': '',

        // Multi select options
        LEADCF106: '', // on-site PD
        LEADCF109: '', // homegrown
        LEADCF104: '', // on-site UoS
        LEADCF103: '', // large district support

        LEADCF10: '', // how can we help

        receiveEmails: false,
        returnURL: 'https://readingandwritingproject.org'
      }}
      validationSchema={PDFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setValues, setFieldValue }) => (
        <Form>
          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>First Name</FormLabel>
              <FormField
                type="text"
                name="First Name"
                placeholder="First Name"
                maxLength="40"
              />
              <FormError
                error={errors['First Name']}
                touched={touched['First Name']}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Last Name</FormLabel>
              <FormField
                type="text"
                name="Last Name"
                placeholder="Last Name"
                maxLength="80"
              />
              <FormError
                error={errors['Last Name']}
                touched={touched['Last Name']}
              />
            </FormGroup>
          </div>

          <div className="w-full md:w-1/2 mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <FormField
                type="text"
                name="LEADCF1"
                placeholder="Teacher"
                maxLength="255"
              />
              <FormError error={errors.LEADCF1} touched={touched.LEADCF1} />
            </FormGroup>
          </div>

          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="Email"
                placeholder="Email"
                maxLength="100"
              />
              <FormError error={errors.Email} touched={touched.Email} />
            </FormGroup>

            <FormGroup>
              <FormLabel isRequired={false}>Phone</FormLabel>
              <FormField
                type="text"
                name="Phone"
                placeholder="555-555-5555"
                maxLength="30"
              />
              <FormError error={errors.Phone} touched={touched.Phone} />
            </FormGroup>
          </div>

          <div className="w-full mb-4">
            <FormGroup>
              <label className="flex" htmlFor="LEADCF102">
                <Field
                  className="h-6 w-6 cursor-pointer"
                  type="checkbox"
                  name="LEADCF102"
                  value={values.LEADCF102}
                  checked={values.LEADCF102}
                />{' '}
                <span className="ml-2 sans-serif text-gray-700 font-bold">
                  Please Check if Your Organization is Located in New York City
                </span>
              </label>
            </FormGroup>
          </div>

          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Organization Name</FormLabel>
              <FormField
                type="text"
                name="Company"
                placeholder="Organization Name"
                maxLength="250"
              />
              <FormError error={errors.Company} touched={touched.Company} />
            </FormGroup>

            <FormGroup>
              <FormLabel isRequired>School District</FormLabel>
              <FormField
                type="text"
                name="LEADCF11"
                placeholder="School District"
              />
              <FormError error={errors.LEADCF11} touched={touched.LEADCF11} />
            </FormGroup>
          </div>

          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Address</FormLabel>
              <FormField
                type="text"
                name="Street"
                placeholder="Address"
                maxLength="250"
              />
              <FormError error={errors.Street} touched={touched.Street} />
            </FormGroup>
          </div>

          <div className="w-full mr-4 flex flex-col md:flex-row">
            <FormGroup>
              <FormLabel isRequired>City</FormLabel>
              <FormField
                type="text"
                name="City"
                placeholder="City"
                maxLength="30"
              />
              <FormError error={errors.City} touched={touched.City} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>State</FormLabel>
              <FormField
                type="text"
                name="State"
                placeholder="State"
                maxLength="30"
              />
              <FormError error={errors.State} touched={touched.State} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Zip Code</FormLabel>
              <FormField
                type="text"
                name="Zip Code"
                placeholder="Zip Code"
                maxLength="30"
              />
              <FormError
                error={errors['Zip Code']}
                touched={touched['Zip Code']}
              />
            </FormGroup>
          </div>

          <div className="flex flex-col">
            <FormGroup>
              <h2 className="text-primary text-2xl font-semibold sans-serif">
                Please tell us which services you are interested in
              </h2>
            </FormGroup>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="LEADCF106">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="LEADCF106"
                    value={values.LEADCF106}
                    checked={values.LEADCF106}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700 font-bold">
                    On-Site Professional Development Services
                  </span>
                </label>
              </FormGroup>
            </div>

            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="LEADCF109">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="LEADCF109"
                    value={values.LEADCF109}
                    checked={values.LEADCF109}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700 font-bold">
                    Homegrown Summer Institutes
                  </span>
                </label>
              </FormGroup>
            </div>

            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="LEADCF104">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="LEADCF104"
                    value={values.LEADCF104}
                    checked={values.LEADCF104}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700 font-bold">
                    Quick Start Conference Days
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="LEADCF103">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="LEADCF103"
                    value={values.LEADCF103}
                    checked={values.LEADCF103}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700 font-bold">
                    Large District Support
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="mt-4">
            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>
                  Tell us more about what you are interested in
                </FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="LEADCF10"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.LEADCF10} touched={touched.LEADCF10} />
            </FormGroup>
          </div>

          <div className="flex flex-col mb-3 mt-4">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="h-6 w-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 sans-serif text-gray-700">
                    Yes, I want to receive emails from the Reading and Writing
                    Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface FormTypeButtonProps {
  text: string;
  isSelected: boolean;
  onClick: any;
}

const FormTypeButton = (props: FormTypeButtonProps) => {
  const classNames = classnames({
    'mr-6 border px-4 py-2 rounded-full font-bold hover:bg-primary hover:text-white sans-serif focus:outline-none text-sm sm:text-base mb-2': true,
    'bg-primary text-white': props.isSelected,
    'border-gray-400 text-gray-500': !props.isSelected
  });

  return (
    <button className={classNames} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

interface FormLabelProps {
  isRequired?: boolean;
  children: React.ReactNode;
}

const FormLabel = (props: FormLabelProps) => {
  return (
    <label>
      <span className="text-gray-600 font-semibold mb-2 sans-serif">
        {props.children}
      </span>{' '}
      {props.isRequired && <span className="text-red-500">*</span>}
    </label>
  );
};

interface FormFieldProps {
  type: string;
  name: string;
  placeholder: string;
  component?: string;
  rows?: string;
  maxLength?: string;
}

const FormField = (props: FormFieldProps) => {
  return (
    <Field {...props} className="border border-gray-300 px-3 py-2 rounded" />
  );
};

interface FormGroupProps {
  children: React.ReactNode;
}
const FormGroup = (props: FormGroupProps) => {
  return <div className="flex flex-col m-2 w-full mt-2">{props.children}</div>;
};

interface FormErrorProps {
  error: string | undefined;
  touched: boolean | undefined;
}

const FormError: React.FC<FormErrorProps> = props => {
  return (
    <p className="text-sm text-red-600 mt-1">
      {props.error && props.touched ? props.error : ' '}
    </p>
  );
};

enum INQUIRY_TYPES {
  EVENTS,
  PD
}

enum SUBMIT_STATE {
  IDLE,
  SUBMITTING,
  SUBMIT_ERROR,
  SUBMIT_SUCCESS
}

enum EVENT_FAQ_STATE {
  VISIBLE,
  HIDDEN
}

enum SERVICES_STATE {
  VISIBLE,
  HIDDEN
}

const Contact = props => {
  const [selectedInquiryType, setSelectedInquiryType] = useState(
    INQUIRY_TYPES.EVENTS
  );
  const [submitState, setSubmitState] = useState(SUBMIT_STATE.IDLE);
  const [eventFaqsVisible, setEventFaqsVisible] = useState(
    EVENT_FAQ_STATE.HIDDEN
  );
  const [servicesVisible, setServicesVisible] = useState(SERVICES_STATE.HIDDEN);
  const [contactFormLoading, setContactFormLoading] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitForm = useCallback(async values => {
    setContactFormLoading(true);

    const requestInfo = {
      name: values.name,
      position: values.position,
      email: values.email,
      phone: values.phone,
      school: values.school,
      address1: values.address1,
      address2: values.address2,
      receive_emails: values.receiveEmails,
      event_type: values.eventType,
      event_text: values.eventText
    };

    try {
      const req = await fetch(
        `${process.env.GATSBY_FUNCTIONS_PATH}/eventContact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestInfo)
        }
      );
      const sendEmail = await eventContactForm(requestInfo);

      if (!req.ok) {
        setContactFormLoading(false);
        toast.error('Something went wrong');
        return;
      }

      const result = await req.json();

      if (result.error) {
        setContactFormLoading(false);
        throw new Error('Something went wrong!');
      }
      setContactFormLoading(false);
      setFormSubmitted(true);
    } catch (err) {
      console.log('the err', err);
      toast.error('Something went wrong');
    }
  }, []);

  const submitPDForm = useCallback(async values => {
    setContactFormLoading(true);

    const form = new FormData();

    for (let key in values) {
      if (Array.isArray(values[key])) {
        values[key].forEach((val: any) => {
          form.append(key, val);
        });
      } else if (
        [
          'LEADCF102',
          'LEADCF106',
          'LEADCF109',
          'LEADCF104',
          'LEADCF103'
        ].includes(key)
      ) {
        if (values[key] === true) {
          form.append(key, 'on');
        } else {
          form.append(key, 'off');
        }
      } else {
        form.append(key, values[key]);
      }
    }

    setContactFormLoading(true);

    const req = await fetch('https://crm.zoho.com/crm/WebToLeadForm', {
      method: 'post',
      body: form
    });

    setContactFormLoading(false);
    setFormSubmitted(true);
  }, []);

  const data = useStaticQuery(graphql`
    {
      allContentfulEventFaq(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            question
            answer {
              answer
            }
            group
          }
        }
      }
      allContentfulService(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: ASC, fields: pagePosition }
      ) {
        edges {
          node {
            id
            name
            description {
              description
            }
          }
        }
      }
    }
  `);

  const services = extractContent(data.allContentfulService);

  return (
    <Layout>
      <SEO title="Contact" />
      <ToastContainer />
      <ContentContainer>
        <div className="w-full lg:w-3/4 m-auto">
          <Card>
            {formSubmitted ? (
              <section>
                <CardHeading>Thank You!</CardHeading>
                <p className="mt-4 text-lg">
                  We always attempt to respond within 7 business days. If you do
                  not hear back from us within this time frame, please send us
                  an email at{' '}
                  <a
                    href="mailto:advancingliteracy@tc.columbia.edu"
                    className="text-primary hover:underline"
                  >
                    advancingliteracy@tc.columbia.edu
                  </a>
                  .
                </p>
                <p className="mt-4 text-lg">
                  If you prefer to reach us by phone or if you have trouble
                  reaching us by e-mail, please call the Advancing Literacy
                  office at 212-678-3104.
                </p>
                <p className="mt-4 text-lg">
                  We look forward to working with you!
                </p>
                <div className="mt-4">
                  <Link to="/">
                    <Button text="&larr; Home" />
                  </Link>
                </div>
              </section>
            ) : (
              <section>
                <CardHeading>Contact Us</CardHeading>
                <section className="my-4 text-gray-700 w-full">
                  <p>
                    Thank you for your interest in the work of Advancing
                    Literacy. We welcome your correspondence related to our
                    professional development services and events. Please make
                    use of the tabs below to direct your questions to the
                    appropriate member of our staff.
                  </p>
                </section>
                <section className="my-6">
                  <p className="sans-serif text-primary text-2xl font-bold">
                    Inquiry Type
                  </p>
                  <p className="text-gray-700 mt-2">
                    Which service would you like to contact Advancing Literacy
                    about?
                  </p>
                  <div className="flex flex-col md:flex-row mt-4 justify-center">
                    <FormTypeButton
                      text="Events (Institutes, Workshops, etc.)"
                      isSelected={selectedInquiryType === INQUIRY_TYPES.EVENTS}
                      onClick={() => {
                        setServicesVisible(SERVICES_STATE.HIDDEN);
                        setSelectedInquiryType(INQUIRY_TYPES.EVENTS);
                      }}
                    />
                    <FormTypeButton
                      text="School/District Level Professional Development Services"
                      isSelected={selectedInquiryType === INQUIRY_TYPES.PD}
                      onClick={() => {
                        setEventFaqsVisible(EVENT_FAQ_STATE.HIDDEN);
                        setSelectedInquiryType(INQUIRY_TYPES.PD);
                      }}
                    />
                  </div>
                </section>
                <section className="mt-10">
                  <h2 className="text-primary text-2xl font-semibold ml-2 mb-2">
                    {selectedInquiryType === INQUIRY_TYPES.EVENTS ? (
                      <span className="sans-serif">Events Inquiry</span>
                    ) : (
                      <span className="sans-serif">
                        Professional Development Inquiry
                      </span>
                    )}
                  </h2>
                  {selectedInquiryType === INQUIRY_TYPES.EVENTS ? (
                    <div className="my-4">
                      <Button
                        text="Read our Event FAQs"
                        onClick={() =>
                          setEventFaqsVisible(EVENT_FAQ_STATE.VISIBLE)
                        }
                      />
                    </div>
                  ) : (
                    <div className="my-4">
                      <Button
                        text="Learn About Our Services"
                        onClick={() =>
                          setServicesVisible(SERVICES_STATE.VISIBLE)
                        }
                      />
                    </div>
                  )}
                  {eventFaqsVisible === EVENT_FAQ_STATE.VISIBLE ? (
                    <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
                      <div className="flex justify-end">
                        <button
                          className="text-3xl my-auto m-2"
                          onClick={() =>
                            setEventFaqsVisible(EVENT_FAQ_STATE.HIDDEN)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-primary hover:text-primary-dark cursor-pointer"
                          />
                        </button>
                      </div>
                      <FaqSection
                        faqs={extractContent(data.allContentfulEventFaq)}
                        hasShadow={false}
                      />
                    </aside>
                  ) : null}
                  {servicesVisible === SERVICES_STATE.VISIBLE ? (
                    <aside className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-2xl fixed top-0 bottom-0 right-0 bg-white pt-4 overflow-scroll z-10">
                      <div className="flex justify-end">
                        <button
                          className="text-3xl my-auto m-2"
                          onClick={() =>
                            setServicesVisible(SERVICES_STATE.HIDDEN)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-primary hover:text-primary-dark cursor-pointer"
                          />
                        </button>
                      </div>
                      <div className="px-8">
                        <h2 className="text-primary font-bold text-3xl mb-4">
                          Our Services
                        </h2>
                      </div>
                      {services.map(service => (
                        <>
                          <div className="bg-white rounded-2xl p-8 mb-2 content-body">
                            <p className="font-bold">{service.name}</p>
                            <div className="text-gray-600">
                              <ContentfulContent
                                content={service.description.description}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </aside>
                  ) : null}

                  {selectedInquiryType === INQUIRY_TYPES.EVENTS && (
                    <EventInquiryForm
                      submitState={submitState}
                      onSubmit={submitForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                  {selectedInquiryType === INQUIRY_TYPES.PD && (
                    <PDInquiryForm
                      submitState={submitState}
                      onSubmit={submitPDForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                </section>
                <section className="border border-gray-200 rounded-lg p-6 flex flex-col md:flex-row mt-10">
                  <div className="mr-8 flex flex-col">
                    <FormLabel>Email</FormLabel>
                    <a
                      href="mailto:advancingliteracy@tc.columbia.edu"
                      className="text-primary underline"
                    >
                      advancingliteracy@tc.columbia.edu
                    </a>
                  </div>
                  <div className="flex flex-col mt-4 sm:mt-0">
                    <FormLabel>Phone</FormLabel>
                    <p className="text-gray-600">(212) 678-3104</p>
                  </div>
                </section>
              </section>
            )}
          </Card>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Contact;
